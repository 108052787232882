import { useState, useEffect } from 'react'
import './Pagination.css'
import classNames from 'classnames'

const Pagination = ({ currentPage, totalPage, prevPage, nextPage, selectPage }) => {
    const [paging, setPaging] = useState([])

    useEffect(() => {
        setPaging(createPaging(Number(currentPage), totalPage))
        // eslint-disable-next-line
    }, [currentPage, totalPage])

    const createPaging = (currentPage, totalPage) => {
        let array = []

        let startPage = currentPage - 2
        let endPage = currentPage + 2

        if (startPage <= 0) {
            endPage -= startPage - 1
            startPage = 1
        }

        if (endPage > totalPage) {
            endPage = totalPage
        }

        if (currentPage !== 1 && totalPage >= 4) array.push("<")

        if (startPage > 1) {
            if (startPage === 2) array.push(1)
            else array.push(1, "...")
        }

        for (let i = startPage; i <= endPage; i++) array.push(i)

        if (endPage < totalPage) {
            if (endPage === totalPage - 1) array.push(totalPage)
            else array.push("...", totalPage)
        }

        if (currentPage !== totalPage && totalPage >= 4) array.push(">")

        return array
    }

    const click = text => {
        if (typeof text === 'number') {
            selectPage(text)
        } else if (text === '<') {
            prevPage()
        } else if (text === '>') {
            nextPage()
        }
    }

    return (
        totalPage > 1
            ?
            <div className="pagination">
                {
                    paging.map((p, index) =>
                        <button
                            key={index}
                            className={classNames("pagination-button", p.toString() === currentPage ? "primary-color" : "unselected")}
                            onClick={() => click(p)}
                        >
                            <code>{p}</code>
                        </button>
                    )
                }
            </div>
            :
            null
    )
}

export default Pagination