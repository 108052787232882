import { useState, useEffect } from 'react'
import { useHistory } from "react-router-dom"

import InputMask from 'react-input-mask'

import { countOrdersBook } from '../../services/service.module'

import Headers from '../../components/headers/Headers'
import OrdersTable from './OrdersTable'
import Pagination from '../../components/pagination/Pagination'


const OrdersBook = () => {
    const history = useHistory()

    const [count, setCount] = useState(null)
    const [totalPage, setTotalPage] = useState(0)
    const [currentPage, setCurrentPage] = useState('1')
    const [numberPerPage] = useState(20)
    const [offset, setOffset] = useState(null)

    useEffect(() => {
        countOrdersBook().then(result => {
            setCount(result)
        })

        const urlParams = new URLSearchParams(window.location.search)
        const page = urlParams.get('page')
        if (page) setCurrentPage(page)
    }, [])

    useEffect(() => {
        if (count) {
            setTotalPage(Math.ceil(count / numberPerPage))
        }
        // eslint-disable-next-line
    }, [count])

    useEffect(() => {
        setOffset(calOffset(numberPerPage, currentPage))
        // eslint-disable-next-line
    }, [currentPage])

    const calOffset = (numberPerPage, currentPage) => {
        return numberPerPage * (currentPage - 1)
    }

    const prevPage = () => {
        history.push(`?page=${Number(currentPage) - 1}`)
        setCurrentPage((Number(currentPage) - 1).toString())
    }

    const nextPage = () => {
        history.push(`?page=${Number(currentPage) + 1}`)
        setCurrentPage((Number(currentPage) + 1).toString())
    }

    const selectPage = no => {
        history.push(`?page=${no}`)
        setCurrentPage(no.toString())
    }

    const toAddOrderBook = () => {
        history.push("/add")
    }

    return (
        <>
            <Headers heading="Orders Book" Filter={<Filter />} toAddFrom={toAddOrderBook} />
            {
                offset != null
                    ?
                    <OrdersTable limit={numberPerPage} offset={offset} />
                    :
                    null
            }
            <Pagination currentPage={currentPage} totalPage={totalPage} prevPage={prevPage} nextPage={nextPage} selectPage={selectPage} />
        </>
    )
}

export default OrdersBook

const Filter = () => {
    return (
        <>
            <label className="first-label">date</label>
            <InputMask className="short-text-input" mask="99 / 99 / 99" placeholder="dd / mm / yy" />
            <label className="middle-label">to</label>
            <InputMask className="short-text-input" mask="99 / 99 / 99" placeholder="dd / mm / yy" />
        </>
    )
}