import { useState, useEffect } from 'react'
import { useHistory } from "react-router-dom"

import classNames from 'classnames'
import moment from 'moment'

import { addOrderBook, getOrderBook, editOrderBook } from '../../services/service.module'

import Loading from '../../components/loading/Loading'

import Title from '../../components/title/Title'
import RadioButton from '../../components/radioButton/RadioButton'

const OrderForm = () => {
    const history = useHistory()

    useEffect(() => {
        const path = window.location.pathname

        if (path === '/add') {
            setMode('add')
            setIsLoading(false)
            setDefaultInput()
        } else {
            setMode('edit')

            let id = path.split('/')[2]
            setEditId(id)
            setIsLoading(false)
            setOldInput(id)
        }
        // eslint-disable-next-line
    }, [])

    const setDefaultInput = () => [
        setInputs({ ...inputs, paid_date: moment().format('YYYY-MM-DD'), sent_date: moment().format('YYYY-MM-DD') })
    ]

    const setOldInput = id => {
        getOrderBook(id).then(result => {
            let { shipper, commodities, quantity, packing, inv_no, vessel, paid_date, sent_date, intervention, destination } = result
            setInputs({ ...inputs, shipper, commodities, quantity, packing, inv_no, vessel, paid_date: moment(paid_date).format('YYYY-MM-DD'), sent_date: moment(sent_date).format('YYYY-MM-DD'), intervention, destination })
        })
    }

    const [mode, setMode] = useState()
    const [editId, setEditId] = useState('')
    const [isLoading, setIsLoading] = useState(true)

    const [inputs, setInputs] = useState({
        shipper: "",
        commodities: "",
        quantity: "",
        packing: "",
        inv_no: "",
        vessel: "",
        paid_date: "",
        sent_date: "",
        intervention: 0,
        destination: ""
    })

    const handleInput = e => setInputs({ ...inputs, [e.target.name]: e.target.value })
    const handleIntervention = no => setInputs({ ...inputs, intervention: no })

    const toOrdersBook = () => history.push("/")

    const confirm = () => {
        if (mode === 'add') addHandle()
        else editHandle()
    }

    const addHandle = () => {
        addOrderBook(formatData(inputs)).then(() => {
            toOrdersBook()
        })
    }

    const editHandle = () => {
        editOrderBook(editId, formatData(inputs)).then(() => {
            toOrdersBook()
        })
    }

    const formatData = object => {
        let newObj = object

        newObj["date"] = moment().format('YYYY-MM-DD')

        return newObj
    }

    return (
        isLoading
            ?
            <Loading />
            :
            <div>
                <Title text={mode === 'add' ? `New Order` : `Edit Order ${editId}`} />

                <div className="form-row">
                    <div className="form-label-container"><label>Shipper</label></div>
                    <input className="normal-text-input" name="shipper" onChange={handleInput} value={inputs.shipper} />
                </div>
                <div className="form-row">
                    <div className="form-label-container"><label>Commodities</label></div>
                    <input className="normal-text-input" name="commodities" onChange={handleInput} value={inputs.commodities} />
                </div>
                <div className="form-row">
                    <div className="form-label-container"><label>Quantity (mt)</label></div>
                    <input className="normal-text-input" type="number" name="quantity" onChange={handleInput} value={inputs.quantity} />
                </div>
                <div className="form-row">
                    <div className="form-label-container"><label>Packing</label></div>
                    <input className="normal-text-input" name="packing" onChange={handleInput} value={inputs.packing} />
                </div>
                <div className="form-row">
                    <div className="form-label-container"><label>Inv. no.</label></div>
                    <input className="normal-text-input" name="inv_no" onChange={handleInput} value={inputs.inv_no} />
                </div>
                <div className="form-row">
                    <div className="form-label-container"><label>Vessel</label></div>
                    <input className="normal-text-input" name="vessel" onChange={handleInput} value={inputs.vessel} />
                </div>
                <div className="form-row">
                    <div className="form-label-container"><label>Intervention</label></div>
                    <div>
                        <div className="radio-button" onClick={() => handleIntervention(0)}>
                            <RadioButton checked={inputs.intervention === 0} />
                            <label className="radio-button-label">Loading Inspection</label>
                        </div>
                        <div className="radio-button" onClick={() => handleIntervention(1)}>
                            <RadioButton checked={inputs.intervention === 1} />
                            <label className="radio-button-label" >Loading Inspection + Fumigation</label>
                        </div>
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-label-container"><label>Paid Date</label></div>
                    <input className="normal-text-input" type="date" name="paid_date" onChange={handleInput} value={inputs.paid_date} />
                </div>
                <div className="form-row">
                    <div className="form-label-container"><label>Sent Date</label></div>
                    <input className="normal-text-input" type="date" name="sent_date" onChange={handleInput} value={inputs.sent_date} />
                </div>

                <div className="form-row">
                    <div className="form-label-container"><label>Destination</label></div>
                    <input className="normal-text-input" name="destination" onChange={handleInput} value={inputs.destination} />
                </div>
                <div className="form-last-row">
                    <button className={classNames("main-button-outline", "primary-outline-color")} onClick={toOrdersBook}>Cancel</button>
                    <button className={classNames("main-button", "primary-color")} onClick={confirm} >Confirm</button>
                </div>
            </div >
    )
}

export default OrderForm