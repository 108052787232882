import { baseURL, headers } from './service.config'
import axios from 'axios'

export const login = (username, password) => {
    const object = { username, password }

    const promise = axios.post(`${baseURL}/login`, object)
        .then(res => {
            localStorage.setItem("token", res.data.token)

            return {
                status: res.status,
                token: res.data.token
            }
        })
        .catch(error => {
            return {
                status: error.response.status,
                message: error.response.data.message
            }
        })

    return promise
}

export const logout = () => {
    localStorage.removeItem("token")
}

export const checkTokenExist = () => {
    return localStorage.hasOwnProperty("token")
}

export const verifyToken = () => {
    const promise = axios.get(`${baseURL}/verifyToken`, headers())
        .then(res => {
            return {
                status: res.status,
                message: res.data.message,
                token: res.data.token
            }
        }).catch(error => {
            console.log(error)
            return {
                status: error.response.status,
                message: error.response.data.message
            }
        })

    return promise
}


