import { Provider } from 'react-redux'
import { createStore } from 'redux'
import reducers from './reducers'

import App from './App'

export const store = createStore(reducers)

const Main = () => {    
    return (
        <Provider store={store}>
            <App />
        </Provider>
    )
}

export default Main

