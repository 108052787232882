import { store } from '../Main'

export const baseURL = 'https://isc1-8b39a.et.r.appspot.com'

export const headers = () => {
    const state = store.getState()
    const token = state.user.token

    return { headers: { authorization: `Bearer ${token}` } }
}


