import './App.css'
import './styles/colors.css'
import './styles/button.css'
import './styles/form.css'
import './styles/input.css'

import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { checkTokenExist, verifyToken } from './services/Authentication'

import { setIsUserLoading, setIsLoggedIn, setUsername, setRole, resetUser, setToken } from './actions/user'

import Loading from './components/loading/Loading'
import Login from './pages/login/Login'
import Sidebar from './components/sidebar/Sidebar'
import Routes from './pages/Routes'

const App = () => {
    const dispatch = useDispatch()

    const isUserLoading = useSelector(state => state.user.isUserLoading)
    const isLoggedIn = useSelector(state => state.user.isLoggedIn)

    useEffect(() => {
        dispatch(setToken(localStorage.getItem("token")))

        if (checkTokenExist()) {
            verifyToken().then(result => {
                if (result.status === 200) {

                    dispatch(setIsUserLoading(false))
                    dispatch(setIsLoggedIn(true))
                    dispatch(setUsername(result.token.user.username))
                    dispatch(setRole(result.token.user.role))
                } else {
                    dispatch(setIsUserLoading(false))
                    dispatch(resetUser())
                }
            })
        } else {
            dispatch(setIsUserLoading(false))
            dispatch(resetUser())
        }

        // eslint-disable-next-line
    }, [isLoggedIn])

    return (
        <div>
            {
                isUserLoading
                    ?
                    <Loading />
                    :
                    isLoggedIn
                        ?
                        <>
                            <Sidebar />
                            <div className="page-container">
                                <div className="cover">
                                    <Routes />
                                </div>
                            </div>
                        </>
                        :
                        <Login />
            }
        </div>
    )
}

export default App
