import './Heading.css'

import classNames from 'classnames'
import { FaPlus } from 'react-icons/fa'

const Heading = ({ heading, toAddFrom }) => {
    return (
        <div className="heading-container">
            <h1 className="primary-text-color">{heading}</h1>
            <button className={classNames("add-button", "primary-color")} onClick={toAddFrom}><FaPlus className="add-icon" /></button>
        </div>
    )
}

export default Heading