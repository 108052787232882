import './index.css'

import React from 'react'
import ReactDOM from 'react-dom'

import Main from './Main'

import reportWebVitals from './reportWebVitals'

ReactDOM.render(
    <React.StrictMode>
        <Main />
    </React.StrictMode>,
    document.getElementById('root')
)

reportWebVitals()
