import { baseURL, headers } from './service.config'
import axios from 'axios'

export const getOrdersBook = (limit, offset) => {
    const promise = axios.get(`${baseURL}/ordersBook?limit=${limit}&offset=${offset}`, headers())
        .then(res => {
            return res.data.results
        })
        .catch(error => {
            console.log(error)
        })

    return promise
}

export const countOrdersBook = () => {
    const promise = axios.get(`${baseURL}/count/ordersBook/`, headers())
        .then(res => {
            return res.data.result[0].count
        })
        .catch(error => {
            console.log(error)
        })

    return promise
}

export const getOrderBook = id => {
    const promise = axios.get(`${baseURL}/orderBook/${id}`, headers())
        .then(res => {
            let result = res.data.result[0]
            if (result.intervention === null) result['intervention'] = 0
            return res.data.result[0]
        })
        .catch(error => {
            console.log(error)
        })

    return promise
}

export const addOrderBook = object => {
    const promise = axios.post(`${baseURL}/orderBook`, object, headers())
        .then(res => {
            return res.data.results
        })
        .catch(error => {
            console.log(error)
        })

    return promise
}

export const editOrderBook = (id, object) => {
    const promise = axios.put(`${baseURL}/orderBook/${id}`, object, headers())
        .then(res => {
            return {
                message: res.data.message,
                status: res.status
            }
        })
        .catch(error => {
            console.log(error)
        })

    return promise
}

export const deleteOrderBook = id => {
    const promise = axios.delete(`${baseURL}/orderBook/${id}`, headers())
        .then(res => {
            return {
                message: res.data.message,
                status: res.status
            }
        })
        .catch(error => {
            console.log(error)
        })

    return promise
}