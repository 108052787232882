import './Login.css'

import { useState } from 'react'
import { useDispatch } from 'react-redux'

import classNames from 'classnames'

import { login } from '../../services/Authentication'

import Title from '../../components/title/Title'
import { setIsLoggedIn } from '../../actions/user'

const Login = () => {
    const dispatch = useDispatch()

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [message, setMessage] = useState('')

    const handleUsername = e => setUsername(e.target.value)
    const handlePassword = e => setPassword(e.target.value)

    const confirm = () => {
        login(username, password).then(result => {
            if (result.status === 200) {
                dispatch(setIsLoggedIn(true))
            } else {
                setPassword('')
                setMessage(result.message)
            }
        })
    }

    return (
        <div className="login-cover">
            <div className="login-container">
                <Title text="Login" />

                {
                    message
                        ?
                        <div className="invalid-field">* {message}</div>
                        :
                        null
                }

                <div className="form-row">
                    <div className="form-label-container"><label>Username</label></div>
                    <input className="normal-text-input" value={username} onChange={handleUsername} />
                </div>
                <div className="form-row">
                    <div className="form-label-container"><label>Password</label></div>
                    <input className="normal-text-input" type="password" value={password} onChange={handlePassword} />
                </div>
                <div className="form-last-row">
                    <button className={classNames("main-button", "primary-color")} onClick={confirm}>Confirm</button>
                </div>
            </div>
        </div>
    )
}

export default Login