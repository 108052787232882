import {
    BrowserRouter as Router,
    Route
} from "react-router-dom"

import OrdersBook from './ordersBook/OrdersBook'
import OrderForm from './ordersBook/OrderForm'

const Routes = () => {
    return (
        <Router>
            <Route path="/" exact>
                <OrdersBook />
            </Route>
            <Route path="/add">
                <OrderForm />
            </Route>
            <Route path="/edit/:id">
                <OrderForm />
            </Route>
        </Router>
    )
}

export default Routes