
import {
    SET_IS_USER_LOADING,
    SET_IS_LOGGED_IN,
    SET_TOKEN,
    SET_USERNAME,
    SET_ROLE,
    RESET_USER
} from './types'

export const setIsUserLoading = bool => {
    return {
        type: SET_IS_USER_LOADING,
        payload: bool
    }
}

export const setIsLoggedIn = bool => {
    return {
        type: SET_IS_LOGGED_IN,
        payload: bool
    }
}

export const setToken = token => {
    return {
        type: SET_TOKEN,
        payload: token
    }
}

export const setUsername = username => {
    return {
        type: SET_USERNAME,
        payload: username
    }
}

export const setRole = role => {
    return {
        type: SET_ROLE,
        payload: role
    }
}

export const resetUser = () => {
    return {
        type: RESET_USER
    }
}