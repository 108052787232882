import './Table.css'

import { FiEdit2 } from 'react-icons/fi'
import { FaTrash } from 'react-icons/fa'

const Table = ({ heads, rows, isAction, onEdit, onDelete }) => {
    return (
        <table className="table">
            <thead>
                <tr>
                    {
                        heads.map((head, i) =>
                            <th key={i} className="primary-color-no-hover">{head}</th>
                        )
                    }
                    {isAction ? <th className="primary-color-no-hover">Action</th> : null}
                </tr>
            </thead>
            <tbody>
                {
                    rows.map((row, i) =>
                        <tr key={i}>
                            {
                                row.map((field, j) =>
                                    <td key={j}>{field}</td>
                                )
                            }
                            {isAction
                                ?
                                <td>
                                    <button className="pain-button" onClick={() => onEdit(row[0])}>
                                        <FiEdit2 className="primary-text-color" size={15} />
                                    </button>
                                    <button className="pain-button" onClick={() => onDelete(row[0])}>
                                        <FaTrash className="primary-text-color" size={15} />
                                    </button>
                                </td>
                                :
                                null
                            }
                        </tr>
                    )
                }
            </tbody>
        </table>
    )
}

export default Table