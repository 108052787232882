import './Title.css'
import classNames from 'classnames'

const Title = ({ text }) => {
    return (
        <div className="title-container">
            <h1 className={classNames("primary-text-color", "title")}>{text}</h1>
        </div>
    )
}

export default Title