import {
    SET_IS_USER_LOADING,
    SET_IS_LOGGED_IN,
    SET_TOKEN,
    SET_USERNAME,
    SET_ROLE,
    RESET_USER
} from '../actions/types'

const INITIAL_STATE = {
    token: null,
    isUserLoading: true,
    isLoggedIn: false,
    username: '',
    role: ''
}

const user = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_IS_USER_LOADING:
            return { ...state, isUserLoading: action.payload }
        case SET_IS_LOGGED_IN:
            return { ...state, isLoggedIn: action.payload }
        case SET_TOKEN:
            return { ...state, token: action.payload }
        case SET_USERNAME:
            return { ...state, username: action.payload }
        case SET_ROLE:
            return { ...state, role: action.payload }
        case RESET_USER:
            return { ...state, token: null, isLoggedIn: false, username: '', role: '' }
        default:
            return state
    }
}

export default user