import './Loading.css'
import { FadeLoader } from 'react-spinners'

const Loading = () => {
    return (
        <div className="loading-container">
            <FadeLoader color="#777" loading={true} height={15} width={5} radius={2} margin={2} />
        </div>
    )
}

export default Loading