import './Sidebar.css'

import { useSelector, useDispatch } from 'react-redux'

import { GiWhiteBook } from 'react-icons/gi'
import { RiBallPenFill } from 'react-icons/ri'
import { FiLogOut } from 'react-icons/fi'
import { RiUserSharedFill } from 'react-icons/ri'
import { IoDocumentText } from 'react-icons/io5'

import { logout } from '../../services/Authentication'

import { resetUser } from '../../actions/user'

import capitalize from '../../utils/capitalize'

function Sidebar() {
    const dispatch = useDispatch()

    const username = useSelector(state => state.user.username)

    const logoutHandle = () => {
        logout()
        dispatch(resetUser())
    }

    return (
        <div className="sidebar_container">
            <div>
                <div className="sidebar_header">
                    <h3 className="sidebar_title">{capitalize(username)}</h3>
                </div>
                <button className="sidebar_item_button">
                    <GiWhiteBook className="sidebar_icon_selected" />
                    <label className="sidebar_label_selected">Orders Book</label>
                </button>
                <button className="sidebar_item_button">
                    <RiBallPenFill className="sidebar_icon" />
                    <label className="sidebar_label">Job Instruction</label>
                </button>
                <button className="sidebar_item_button">
                    <RiUserSharedFill className="sidebar_icon" />
                    <label className="sidebar_label">Jop Allocation</label>
                </button>

                <div className="sidebar_hr" />

                <button className="sidebar_item_button">
                    <IoDocumentText className="sidebar_icon" />
                    <label className="sidebar_label">Invoice</label>
                </button>
                <button className="sidebar_item_button">
                    <IoDocumentText className="sidebar_icon" />
                    <label className="sidebar_label">Certificate</label>
                </button>
            </div>

            <button className="sidebar_item_button_last" onClick={logoutHandle}>
                <FiLogOut className="sidebar_icon" />
                <label className="sidebar_label">Log Out</label>
            </button>
        </div>
    )
}

export default Sidebar
