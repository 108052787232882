import './RadioButton.css'
import classNames from 'classnames'

const RadioButton = ({ checked }) => {
    return (
        <div className={classNames("radio-button-outside", "primary-border-color")}>
            {
                checked
                    ?
                    <div className={classNames("radio-button-inside", "primary-color")} />
                    :
                    null
            }
        </div>
    )
}

export default RadioButton