import { useState, useEffect } from 'react'
import { useHistory } from "react-router-dom"

import { deleteOrderBook, getOrdersBook } from '../../services/service.module'

import moment from 'moment'
import capitalize from '../../utils/capitalize'

import Loading from '../../components/loading/Loading'
import Table from '../../components/table/Table'

const OrdersTable = ({ limit, offset }) => {
    const history = useHistory()

    useEffect(() => {
        setIsLoading(true)

        getOrdersBook(limit, offset).then(results => {
            setHeads(formatHeads(getHeads(results)))
            setRows(getRows(formatData(results)))
            setIsLoading(false)
        })
        // eslint-disable-next-line
    }, [offset])

    const [isLoading, setIsLoading] = useState(true)
    const [heads, setHeads] = useState([])
    const [rows, setRows] = useState([])

    const getHeads = objects => {
        return Object.keys(objects[0] !== undefined ? objects[0] : objects[1])
    }

    const formatHeads = arrayHeads => {
        return arrayHeads.map(head => head.replace(/_/g, ' ').replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()))
    }

    const getRows = objects => {
        return objects.map(object => Object.values(object))
    }

    const formatData = objects => {
        return objects.map(object => ({
            ...object,
            date: moment(object.date).format('DD/MM/YY'),
            paid_date: moment(object.paid_date).format('DD/MM/YY'),
            sent_date: moment(object.sent_date).format('DD/MM/YY'),
            intervention: formatIntervention(object.intervention),
            editor: capitalize(object.editor)
        }))
    }

    const formatIntervention = no => {
        if (no === 0) return <code>LI</code>
        else return <code>LIF</code>
    }

    const onEdit = id => {
        history.push(`/edit/${id}`)
    }

    const onDelete = id => {
        const c = window.confirm(`Do you want to delete order ${id}?`)

        if (c) {
            deleteOrderBook(id).then(res => {
                if (res.status === 200) window.location.reload()
            })
        }
    }

    return (
        isLoading
            ?
            <Loading />
            :
            <Table heads={heads} rows={rows} isAction onEdit={onEdit} onDelete={onDelete} />
    )
}

export default OrdersTable