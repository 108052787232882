import './Headers.css'

import Heading from './Heading'
// import SearchButton from './SearchButton'

const Headers = ({ heading, Filter, toAddFrom }) => {
    return (
        <div className="headers">
            <Heading heading={heading} toAddFrom={toAddFrom} />
            
            {/* <div className="filter-table">
                {Filter}
                <SearchButton />
            </div> */}
            <div />
        </div>
    )
}

export default Headers